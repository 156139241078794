import React from 'react';

const HelpSupport = () => {
    return (
       
        <div className="bg-gray-100 min-h-screen flex text-left justify-center p-6">
        <div className="bg-white max-w-3xl w-full p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-blue-600 text-center">Help & Support</h2>
          <p className="text-center text-gray-500">Welcome to <span className="font-semibold">Ebeach BD</span> Help & Support! We are here to ensure your shopping experience is smooth, secure, and enjoyable.</p>
  
          <div className="mt-6 space-y-6">
            <div>
              <h5 className="text-lg font-semibold text-gray-700 flex items-center">📦 Order & Shipping</h5>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Track your order status</li>
                <li>Estimated delivery times</li>
                <li>Shipping policies & costs</li>
              </ul>
            </div>
  
            <div>
              <h5 className="text-lg font-semibold text-gray-700 flex items-center">🔄 Returns & Refunds</h5>
              <ul className="list-disc pl-6 text-gray-600">
                <li>How to return an item</li>
                <li>Refund processing time</li>
                <li>Exchange policies</li>
              </ul>
            </div>
  
            <div>
              <h5 className="text-lg font-semibold text-gray-700 flex items-center">💳 Payments & Billing</h5>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Accepted payment methods</li>
                <li>Issues with payment processing</li>
                <li>Billing & invoicing</li>
              </ul>
            </div>
  
            <div>
              <h5 className="text-lg font-semibold text-gray-700 flex items-center">🛒 Account & Orders</h5>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Managing your account</li>
                <li>Updating personal information</li>
                <li>Viewing order history</li>
              </ul>
            </div>
  
            <div>
              <h5 className="text-lg font-semibold text-gray-700 flex items-center">🛠 Technical Support</h5>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Troubleshooting website issues</li>
                <li>Fixing login or checkout problems</li>
                <li>Mobile app support</li>
              </ul>
            </div>
          </div>
  
          <div className="mt-6 border-t pt-4">
            <h5 className="text-lg font-semibold text-gray-700 flex items-center">📞 Get in Touch</h5>
            <p className="text-gray-600">We are committed to providing excellent service. If you have any questions, feel free to reach out!</p>
            <ul className="list-disc pl-6 text-gray-600 mt-2">
              <li><strong>Live Chat:</strong> Instant support from our team</li>
              <li><strong>Email:</strong> <a href="mailto:fahadbd029@gmail.com" className="text-blue-500 underline">fahadbd029@gmail.com</a></li>
              <li><strong>Phone:</strong> <a href="tel:+8801810111389" className="text-blue-500 underline">+8801810111389</a></li>
            </ul>
          </div>
        </div>
      </div>
    );
};

export default HelpSupport;