import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AllShopHome = () => {


    const [home, setHome] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/shop-list`)
            .then(response => response.json())
            .then(data => {
                setHome(data);
                setLoading(false);
            });
    }, []);

    return (
        <div className='mx-auto max-w-[1240px]  items-center grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 mt-10 gap-16 px-10 mb-10'>

            {home?.data?.map(item => (
                <Link to={`/shop/all_product/${item.id}`}>
                    <div className="card rounded-none shadow-xl">
                        <figure className="">
                            <img src={item.photo} alt="Shoes" className="w-[200px] h-[200px] rounded-lg" />
                        </figure>
                        <p className='text-[#be1e2d]'>{item.name}</p>
                    </div>

                </Link>
            ))}
        </div>
    );
};

export default AllShopHome;