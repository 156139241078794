import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaStar } from 'react-icons/fa';

const AllWinterProduct = () => {
    const [home, setHome] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(response => response.json())
            .then(data => {
                setHome(data);
                setLoading(false);
            });
    }, []);

    const newProducts = home?.data?.filter(item => item.condition === 'winter');


    console.log(newProducts)

    const [sortByPrice, setSortByPrice] = useState("");
    const [filteredData, setFilteredData] = useState([]);


    const handleSortByPrice = (order) => {
        const sorted = [...newProducts]; // Create a copy of the original data
        sorted.sort((a, b) => {
            if (order === "asc") {
                return a.offer_price - b.offer_price; // Sort in ascending order
            } else if (order === "desc") {
                return b.offer_price - a.offer_price; // Sort in descending order
            }
            return 0;
        });
        setFilteredData(sorted); // Set the sorted array to filteredData state
        setSortByPrice(order);
    };

    // const handleFilterByPriceRange = (minPrice, maxPrice) => {
    //   const filteredProducts = home.data.filter(product => product.price >= minPrice && product.price <= maxPrice);
    //   setFilteredData(filteredProducts);
    // };
    const handleFilterByPriceRange = (minPrice, maxPrice) => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false; // Uncheck all checkboxes
        });

        const filteredCheckbox = document.getElementById(`${minPrice}-${maxPrice}`);
        filteredCheckbox.checked = true; // Check the clicked checkbox

        const filteredProducts = newProducts.filter(product => product.offer_price >= minPrice && product.offer_price <= maxPrice);
        setFilteredData(filteredProducts);
    };


    const [cart, setCart] = useState([])
    // const handleAddToCart = (itemId) => {
    //     // Retrieve existing cart items from localStorage
    //     const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

    //     // Add the new item ID to the cart
    //     const updatedCartItems = [...existingCartItems, itemId];

    //     // Update the cart state and localStorage
    //     setCart(updatedCartItems);
    //     localStorage.setItem('cart', JSON.stringify(updatedCartItems));

    //     // Display a success toast
    //     toast.success('Added to cart successfully!');
    // };

    const handleAddToCart = (itemId, stock) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('DetailsCart')) || [];

        // Find the index of the item in the existing cart items array
        const itemIndex = existingCartItems.findIndex(item => item.id === itemId);

        // If the item is already in the cart, update its quantity
        if (itemIndex !== -1) {
            // Increment quantity if it doesn't exceed stock
            if (existingCartItems[itemIndex].count < stock) {
                existingCartItems[itemIndex].count += 1;
            } else {
                // Display an alert if the cart exceeds the stock
                alert("You cannot add more of this product. Stock limit reached.");
                return; // Stop further execution
            }
        } else {
            // If the item is not in the cart, add it with quantity 1
            if (1 <= stock) {
                existingCartItems.push({ id: itemId, count: 1, selectedColor: null, selectedSize: null });
            } else {
                // Display an alert if the stock is insufficient
                alert("Sorry, this product is out of stock.");
                return; // Stop further execution
            }
        }

        // Update the cart state and localStorage
        localStorage.setItem('DetailsCart', JSON.stringify(existingCartItems));

        // Display a success toast
        toast.success('Added to cart successfully!');
    };

    const handleAddToFavouriteList = (itemId) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to favourite List successfully!');
    };


    console.log(filteredData)
    return (
        <div className='mt-10 mb-10'>
            {
                filteredData &&

                    filteredData.length > 0 ?

                    <div className='lg:flex   container mx-auto  lg:px-20'>
                        <div class="mt-5 box  rounded-xl border  border-gray-300 bg-white p-6 lg:h-[500px]  lg:w-[30%]">
                            <select className="select mb-5 w-full bg-white select-bordered" value={sortByPrice} onChange={(e) => handleSortByPrice(e.target.value)}>
                                <option value="">Select Any</option>
                                <option value="desc">Higher To Lower</option>
                                <option value="asc">Lower To Higher</option>
                            </select>
                            <div class="box flex flex-col gap-5">
                                <div class="flex items-center">
                                    <input id="100-300" name="100-300" onChange={() => handleFilterByPriceRange(100, 300)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">100 Tk to 300 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="300-600" name="300-600" onChange={() => handleFilterByPriceRange(300, 600)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="300-600">300 Tk to 600 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="600-1000" name="600-1000" onChange={() => handleFilterByPriceRange(600, 1000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="600-1000">600 Tk to 1000 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="1000-1500" name="1000-1500" onChange={() => handleFilterByPriceRange(1000, 1500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="1000-1500">1000 Tk to 1500 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="1500-2000" name="1500-2000" onChange={() => handleFilterByPriceRange(1500, 2000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="1500-2000">1500 Tk to 2000 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="2000-125000" name="2000-125000" onChange={() => handleFilterByPriceRange(2000, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="2000-125000">2000 Tk to 2500 Tk +++++</label>
                                </div>
                                {/* <div class="flex items-center">
                                <input id="2500-125000" name="2500-125000" onChange={() => handleFilterByPriceRange(2500, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300"> 2500 Tk ++</label>
                            </div> */}
                            </div>
                        </div>
                        <div className='lg:w-[70%]'>

                            <div className='mx-auto max-w-[1440px]  items-center grid grid-cols-1 mt-5  px-10'>
                                {filteredData?.map((product, index) => (
                                    // <a href={`/product/product_details/${product.slug}`}>

                                    <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={product.id}>
                                        <a href={`/product/product_details/${product.slug}`}>

                                            <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                                        </a>
                                        <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                            <div className="mt-5 sm:mt-0 ">
                                                <a href={`/product/product_details/${product.slug}`}>
                                                    <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700 text-start">{product.title}</h2>
                                                </a>
                                                <p className='text-black text-start'>Stock: {product.stock}</p>

                                                <div className='flex gap-2'>
                                                    <div>{product.offer_price}</div>
                                                    <div> <del className='text-red-500'>{product.price}</del>BDT</div>
                                                </div>
                                                <div className='flex text-[#FACA15]'>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                </div>
                                            </div>
                                            <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                                <div className="flex justify-end border-gray-100">
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToFavouriteList(product.id)} >
                                                        <svg stroke="currentColor"
                                                            className='h-6 w-6'
                                                            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                                    </button>
                                                </div>
                                                <div className="flex justify-end border-gray-100">
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToCart(product.id, product.stock)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    :
                    <div className='lg:flex   container mx-auto  lg:px-20'>
                        <div class="mt-5 box  rounded-xl border  border-gray-300 bg-white p-6 lg:h-[500px]  lg:w-[30%]">
                            <select className="select mb-5 w-full bg-white select-bordered" value={sortByPrice} onChange={(e) => handleSortByPrice(e.target.value)}>
                                <option value="">Select Any</option>
                                <option value="desc">Higher To Lower</option>
                                <option value="asc">Lower To Higher</option>
                            </select>
                            <div class="box flex flex-col gap-5">
                                <div class="flex items-center">
                                    <input id="100-300" name="100-300" onChange={() => handleFilterByPriceRange(100, 300)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">100 Tk to 300 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="300-600" name="300-600" onChange={() => handleFilterByPriceRange(300, 600)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="300-600">300 Tk to 600 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="600-1000" name="600-1000" onChange={() => handleFilterByPriceRange(600, 1000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="600-1000">600 Tk to 1000 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="1000-1500" name="1000-1500" onChange={() => handleFilterByPriceRange(1000, 1500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="1000-1500">1000 Tk to 1500 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="1500-2000" name="1500-2000" onChange={() => handleFilterByPriceRange(1500, 2000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="1500-2000">1500 Tk to 2000 Tk</label>
                                </div>
                                <div class="flex items-center">
                                    <input id="2000-125000" name="2000-125000" onChange={() => handleFilterByPriceRange(2000, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                    <label className="ml-2 text-gray-700 font-semibold" htmlFor="2000-125000">2000 Tk to 2500 Tk +++++</label>
                                </div>
                                {/* <div class="flex items-center">
                            <input id="2500-125000" name="2500-125000" onChange={() => handleFilterByPriceRange(2500, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                            <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300"> 2500 Tk ++</label>
                        </div> */}
                            </div>
                        </div>
                        <div className='lg:w-[70%]'>

                            <div className='mx-auto max-w-[1440px]  items-center grid grid-cols-1 mt-5  px-10'>
                                {newProducts?.map((product, index) => (
                                    // <a href={`/product/product_details/${product.slug}`}>

                                    <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={product.id}>
                                        <a href={`/product/product_details/${product.slug}`}>

                                            <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                                        </a>
                                        <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                            <div className="mt-5 sm:mt-0 ">
                                                <a href={`/product/product_details/${product.slug}`}>
                                                    <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700 text-start">{product.title}</h2>
                                                </a>
                                                <p className='text-black text-start'>Stock: {product.stock}</p>

                                                <div className='flex gap-2'>
                                                    <div>{product.offer_price}</div>
                                                    <div> <del className='text-red-500'>{product.price}</del>BDT</div>
                                                </div>
                                                <div className='flex text-[#FACA15]'>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                    <FaStar></FaStar>
                                                </div>
                                            </div>
                                            <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                                <div className="flex justify-end border-gray-100">
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToFavouriteList(product.id)} >
                                                        <svg stroke="currentColor"
                                                            className='h-6 w-6'
                                                            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                                                    </button>
                                                </div>
                                                <div className="flex justify-end border-gray-100">
                                                    <button className="bg-[#be1e2d] text-white px-2 py-2 rounded hover:bg-[#be1e2d]" onClick={() => handleAddToCart(product.id, product.stock)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
            }

        </div>
    );
};

export default AllWinterProduct;