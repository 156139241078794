import React, { useEffect, useState } from 'react';
import { FaHeart, FaUser } from 'react-icons/fa';
import image2 from '../../../img/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import Cart from '../../Home/Cart/Cart';


const Nav2 = ({ handleCategoryChange, categories, searchQuery, setSearchQuery, handleSearch, filteredData }) => {
  // console.log(filteredData)
  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  const email = localStorage.getItem('email')
  const user_id = localStorage.getItem('user_id')

  const handleLogout = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('user_id');
    navigate('/')

    // router.push('/admin/login13');
  };


  const click = () => {
    window.location.href = '/';
  }

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // console.log(filteredData)


  // const allCart = JSON.parse(localStorage.getItem('cart'))
  // const DetailsCart = JSON.parse(localStorage.getItem('DetailsCart'))

  // const normalCart = allCart.length
  // const countSum = DetailsCart.reduce((sum, item) => sum + item.count, 0);

  // console.log(countSum + normalCart)

  // const cartLength = countSum + normalCart


  // const [allCart, setAllCart] = useState([]);
  //   const [detailsCart, setDetailsCart] = useState([]);

  //   // State to hold the total count of products in cart
  //   const [cartLength, setCartLength] = useState(0);

  //   // Fetch cart data from localStorage when component mounts
  //   useEffect(() => {
  //     const cartData = JSON.parse(localStorage.getItem('cart')) || [];
  //     const detailsCartData = JSON.parse(localStorage.getItem('DetailsCart')) || [];
  //     setAllCart(cartData);
  //     setDetailsCart(detailsCartData);
  //   }, []);



  //   // Update cart length whenever cart data changes
  //   useEffect(() => {

  //     const countSum = detailsCart.reduce((sum, item) => sum + item.count, 0);

  //     setCartLength( countSum);
  //   }, [allCart, detailsCart]);

  const [allCart, setAllCart] = useState([]);
  const [detailsCart, setDetailsCart] = useState([]);
  const [cartLength, setCartLength] = useState(0);

  // Function to update cart data
  const updateCartData = () => {
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
    const detailsCartData = JSON.parse(localStorage.getItem('DetailsCart')) || [];
    setAllCart(cartData);
    setDetailsCart(detailsCartData);
  };

  // Fetch cart data from localStorage when component mounts
  useEffect(() => {
    updateCartData();
  }, []);

  // Update cart length whenever detailsCart changes
  useEffect(() => {
    const countSum = detailsCart.reduce((sum, item) => sum + item.count, 0);
    setCartLength(countSum);
  }, [detailsCart]);

  // Set interval to refresh cart data every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateCartData();
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  
  const [wishList, setWishList] = useState(0);

  useEffect(() => {
    const updateWishList = () => {
      const storedList = localStorage.getItem('favouriteList');
      if (storedList) {
        const wishlistArray = JSON.parse(storedList);
        setWishList(wishlistArray.length);
      }
    };

    updateWishList(); // Initial call to set the wishlist length on component mount

    const interval = setInterval(updateWishList, 1000); // Update every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);


  return (
    <div>
      {
        filteredData && filteredData.length > 0 ?
          <div className=' bg-slate-100 p-2'>

            <div className='container mx-auto lg:flex'>

              <div className='lg:w-[30%]'>
                <Link className='flex' to={`/`} onClick={click}>
                  <div className=''>
                    <img src={image2} alt="" style={{ width: '50px' }} />
                  </div>
                  <div className=''>
                    <h1 className='text-xl'><strong>E Beach BD</strong></h1>
                    <p><small >Get Ready To Shop</small></p>
                  </div>
                </Link>
              </div>
              <form class="lg:w-[40%]   rounded-e-lg mt-2">
                <div class="flex border-[3px] border-[#be1e2d] rounded-xl">
                  <label for="search-dropdown" class="mb-2 text-sm font-medium  text-gray-900 sr-only dark:text-white">Your Email</label>

                  <select name="" id="" class="block rounded-l-lg  w-[40%] z-20 text-sm text-gray-900 bg-gray-50 border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" onChange={handleCategoryChange}>
                    <option className='text-[#be1e2d]'>Select Category</option>
                    {
                      categories?.data?.map(cat => (
                        <>
                          <option className='text-[#be1e2d]' value={cat.id}>{cat.title}</option>

                        </>

                      ))
                    }
                  </select>

                  <div class="relative w-full ">
                    <input

                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      type="search" id="search-dropdown" class=" block p-2.5 w-full z-20 text-sm text-red-700 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search Product" required />
                    <Link to={``}>

                      <button onClick={handleSearch} type="button" name="search" class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-[#be1e2d] rounded-e-lg border border-red-700 hover:bg-red-700  focus:outline-none focus:ring-red-700 dark:bg-red-700 dark:hover:bg-red-700 dark:focus:ring-red-700">
                        {/* focus:ring-4 */}
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
              <div className='lg:w-[30%] mt-5'>


                {/* You can open the modal using document.getElementById('ID').showModal() method */}
                <>
                  <button className="indicator ml-2 mr-5" onClick={openModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span className="badge badge-sm indicator-item">{cartLength}</span>
                  </button>

                  {modalOpen && (
                    <dialog id="my_modal_4" className="modal" open>
                      <div className="modal-box w-11/12 max-w-5xl">
                        <Cart closeModal={closeModal}></Cart>
                        <div method="dialog" className="card-actions mt-10">
                          <a href={`/cart`} className="btn btn-primary btn-block" onClick={closeModal}>View cart</a>
                        </div>
                        <div className="modal-action">
                          <button className="btn" onClick={closeModal}>Close</button>
                        </div>
                      </div>
                    </dialog>
                  )}
                </>



                <Link to={`my_wish_list`}>

                  <button className="indicator ml-2 mr-5" >
                    <FaHeart />
                    <span className="badge badge-sm indicator-item">{wishList}</span>
                  </button>
                </Link>


                <div className="dropdown dropdown-end ml-2" >
                  <div tabIndex={0} role="button" className="h-5 w-5">
                    <FaUser />
                  </div>
                  <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">


                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                      {token && user_id ?
                        <>
                          <li>
                            <a href={`/user/profile`} className="justify-between">
                              Profile
                              <span className="badge">New</span>
                            </a>
                          </li>
                          <li><a href={`/user/change_password`}>Account Settings</a></li>
                          <li><a href={`/my_order_list`}>My Order</a></li>
                          <li><Link onClick={handleLogout}>Logout</Link></li>
                        </>
                        :
                        <li><a href={`/login`}>Login And Registration</a></li>
                      }
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>

          :
          <div className=' bg-slate-100 p-2'>


            <div className='container mx-auto lg:flex'>

              <div className='lg:w-[30%]'>
                <Link className='flex' to={`/`}>
                  <div className=''>
                    <img src={image2} alt="" style={{ width: '50px' }} />
                    {/* <img src={image} alt="" style={{ width: '50px' }} /> */}
                  </div>
                  <div className=''>
                    <h1 className='text-xl'><strong>E Beach BD</strong></h1>
                    <p><small >Get Ready To Shop</small></p>
                  </div>
                </Link>
              </div>
              <form class="lg:w-[40%]   rounded-e-lg mt-2">
                <div class="flex border-[3px] border-[#be1e2d] rounded-xl">
                  <label for="search-dropdown" class="mb-2 text-sm font-medium  text-gray-900 sr-only dark:text-white">Your Email</label>

                  <select name="" id="" class="block rounded-l-lg w-[40%] z-20 text-sm text-gray-900 bg-gray-50 border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" onChange={handleCategoryChange}>
                    <option className='text-[#be1e2d]'>Select Category</option>
                    {
                      categories?.data?.map(cat => (
                        <>
                          <option className='text-[#be1e2d]' value={cat.id}>{cat.title}</option>

                        </>

                      ))
                    }
                  </select>

                  <div class="relative w-full ">
                    <input

                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-red-700 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search Product" required />
                    <Link to={`/search`}>

                      <button onClick={handleSearch} type="button" name="search" class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-[#be1e2d] rounded-e-lg border border-red-700 hover:bg-red-700  focus:outline-none focus:ring-red-700 dark:bg-red-700 dark:hover:bg-red-700 dark:focus:ring-red-700">
                        {/* focus:ring-4 */}
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
              <div className='lg:w-[30%] mt-5'>


                {/* You can open the modal using document.getElementById('ID').showModal() method */}
                <>
                  <button className="indicator ml-2 mr-5" onClick={openModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span className="badge badge-sm indicator-item">{cartLength}</span>
                  </button>

                  {modalOpen && (
                    <dialog id="my_modal_4" className="modal" open>
                      <div className="modal-box w-11/12 max-w-5xl">
                        <Cart closeModal={closeModal}></Cart>
                        <div method="dialog" className="card-actions mt-10">
                          <Link to={`/cart`} className="btn btn-primary btn-block" onClick={closeModal}>View cart</Link>
                        </div>
                        <div className="modal-action">
                          <button className="btn" onClick={closeModal}>Close</button>
                        </div>
                      </div>
                    </dialog>
                  )}
                </>



                {/* <div className="dropdown dropdown-end ml-2 mr-4" >
            <div tabIndex={0} role="button" className="h-5 w-5">
              <Link to={`my_wish_list`}>
                <FaHeart />
                <span className="badge badge-sm indicator-item">{cartLength}</span>
              </Link>
            </div>
          </div> */}
                <Link to={`my_wish_list`}>

                  <button className="indicator ml-2 mr-5">
                    <FaHeart />
                    <span className="badge badge-sm indicator-item">{wishList}</span>
                  </button>
                </Link>


                <div className="dropdown dropdown-end ml-2" >
                  <div tabIndex={0} role="button" className="h-5 w-5">
                    <FaUser />
                  </div>
                  <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">


                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                      {token && user_id ?
                        <>
                          <li>
                            <Link to={`/user/profile`} className="justify-between">
                              Profile
                              <span className="badge">New</span>
                            </Link>
                          </li>
                          <li><Link to={`/user/change_password`}>Account Settings</Link></li>
                          <li><Link to={`/my_order_list`}>My Order</Link></li>
                          <li><Link onClick={handleLogout}>Logout</Link></li>
                        </>
                        :
                        <li><Link to={`/login`}>Login And Registration</Link></li>
                      }
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>

  );
};

export default Nav2;