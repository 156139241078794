import React, { useEffect, useState } from 'react';

const MeetOurTeam = () => {


    const [team, setTeam] = useState([])

    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/about-us`)
            .then(res => res.json())
            .then(data => setTeam(data))
    }, [])

    return (
        <div >

        <div className='mx-auto max-w-[840px]  items-center grid grid-cols-1 mt-5  px-10'>
            {team?.data?.map((product, index) => (
                // <a href={`/product/product_details/${product.slug}`}>

                <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start" key={product.id}>
                    <a >

                        <img src={product.photo} alt="product-image" className="w-full rounded-lg sm:w-40 lg:h-[100px] md:h-[100px]" />
                    </a>
                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                        <div className="mt-5 sm:mt-0 ">
                            <a >
                                <h2 className="text-lg font-bold text-gray-900 hover:text-blue-700 text-start">{product.title}</h2>
                            </a>
                            <p className='text-black text-start'>Designation: {product.designation}</p>
                            <p className='text-black text-start'>Email: {product.email}</p>
                            <p className='text-black text-start'>Address: {product.address}</p>
                            <p className='text-black text-start'>Short Description: {product.short_description}</p>

                           
                         
                        </div>
                      
                    </div>
                </div>
            ))}
        </div>
    </div>
    );
};

export default MeetOurTeam;