import React from 'react';

const TearmsAndCondition = () => {
    return (
        <div className="bg-gray-100 min-h-screen flex text-left justify-center p-6 ">
            <div className="bg-white max-w-3xl w-full p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold text-blue-600 text-center">Terms & Conditions</h2>
                <p className="text-center text-gray-500">Last Updated: 3 March, 2025</p>

                <div className="mt-6">
                    <h5 className="text-lg font-semibold text-gray-700">1. General Information</h5>
                    <p className="text-gray-600">These Terms & Conditions govern your use of <a href="https://phenomenal-sopapillas-756924.netlify.app/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">E Beach BD</a> and all related services.</p>
                    <p className="text-gray-600">We reserve the right to update, change, or replace any part of these terms at any time.</p>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">2. Account Registration</h5>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li>You must be at least 18 years old to create an account or make a purchase.</li>
                        <li>You are responsible for maintaining the confidentiality of your account and password.</li>
                        <li>Any activity under your account is your responsibility.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">3. Orders & Payments</h5>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li>All orders are subject to availability and confirmation of payment.</li>
                        <li>We accept <strong>Cash on Delivery</strong>.</li>
                        <li>Prices and availability of products are subject to change without notice.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">4. Shipping & Delivery</h5>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li>We aim to process and ship orders within <strong>[X]</strong> business days.</li>
                        <li>Delivery times may vary based on location and shipping method.</li>
                        <li>We are not responsible for delays caused by courier services or customs.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">5. Returns & Refunds</h5>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li>We accept returns within <strong>10</strong> days of delivery.</li>
                        <li>Items must be in their original condition with all packaging and tags.</li>
                        <li>Refunds will be processed within <strong>5</strong> business days upon approval.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">6. User Conduct</h5>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li>You agree not to use our website for any illegal or unauthorized purpose.</li>
                        <li>You must not transmit any malware, spam, or engage in fraudulent activities.</li>
                    </ul>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">7. Intellectual Property</h5>
                    <p className="text-gray-600">All content on our website, including text, images, and logos, is our property and may not be used without permission.</p>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">8. Limitation of Liability</h5>
                    <p className="text-gray-600">We are not liable for any direct, indirect, or incidental damages arising from the use of our website or products.</p>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">9. Governing Law</h5>
                    <p className="text-gray-600">These Terms & Conditions are governed by the laws of <strong>[Your Country/State]</strong>.</p>
                </div>

                <div className="mt-4">
                    <h5 className="text-lg font-semibold text-gray-700">10. Contact Us</h5>
                    <p className="text-gray-600">If you have any questions about these Terms & Conditions, please contact us:</p>
                    <ul className="list-disc pl-6 text-gray-600">
                        <li><strong>Email:</strong> <a href="mailto:ebeachbd@gmail.com" className="text-blue-500 underline">ebeachbd@gmail.com</a></li>
                        <li><strong>Phone:</strong> <a href="tel:+8801810111389" className="text-blue-500 underline">+8801810111389</a></li>
                    </ul>
                </div>

                <p className="text-center mt-6 text-gray-700 font-semibold">By using our website, you agree to these Terms & Conditions. <span className="text-blue-600">Happy shopping!</span> 🛍️</p>
            </div>
        </div>
    );
};

export default TearmsAndCondition;