import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaHeart } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

const ProductSubCategory = () => {
    const { id } = useParams();
    const [shopProduct, setShopProduct] = useState([]);
    const [sortedProduct, setSortedProduct] = useState([]);
    const [sortByPrice, setSortByPrice] = useState("");
    const [visibleItems, setVisibleItems] = useState(10); // Initial number of items to display


    useEffect(() => {
        fetch(`https://backend.ebeachbd.com/api/product-list`)
            .then(res => res.json())
            .then(data => {
                // Filter products based on brand_id
                const filteredProducts = data.data.filter(product => product.subcat_id === id);
                setShopProduct(filteredProducts);
                setSortedProduct(filteredProducts); // Set sortedProduct initially
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, [id]);


    console.log(shopProduct)
    console.log(id)

    useEffect(() => {
        // Check if shopProduct.data is an array before updating sortedProduct
        if (Array.isArray(shopProduct.data)) {
            setSortedProduct([...shopProduct.data]);
        }
    }, [shopProduct]);

    const handleSortByPrice = (order) => {
        const sorted = [...shopProduct]; // Create a copy of the original data
        sorted.sort((a, b) => {
            if (order === "asc") {
                return a.price - b.price; // Sort in ascending order
            } else if (order === "desc") {
                return b.price - a.price; // Sort in descending order
            }
            return 0;
        });
        setSortByPrice(order);
        setSortedProduct(sorted);
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = index => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const [cart, setCart] = useState([])
    // const handleAddToCart = (itemId, stock) => {
    //     // Retrieve existing cart items from localStorage
    //     const existingCartItems = JSON.parse(localStorage.getItem('cart')) || [];

    //     // Count the occurrences of the item in the cart
    //     const itemCountInCart = existingCartItems.filter(item => item === itemId).length;

    //     // Check if adding the item would exceed the available stock
    //     if (itemCountInCart >= stock) {
    //         // Display an alert if the cart exceeds the stock
    //         alert("You cannot add more of this product. Stock limit reached.");
    //         return; // Stop further execution
    //     }

    //     // Add the new item ID to the cart
    //     const updatedCartItems = [...existingCartItems, itemId];

    //     // Update the cart state and localStorage
    //     setCart(updatedCartItems);
    //     localStorage.setItem('cart', JSON.stringify(updatedCartItems));

    //     // Display a success toast
    //     toast.success('Added to cart successfully!');
    // };


    // const handleAddToFavouriteList = (itemId) => {
    //     // Retrieve existing cart items from localStorage
    //     const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

    //     // Add the new item ID to the cart
    //     const updatedCartItems = [...existingCartItems, itemId];

    //     // Update the cart state and localStorage
    //     setCart(updatedCartItems);
    //     localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

    //     // Display a success toast
    //     toast.success('Added to favourite List successfully!');
    // };
    const handleAddToCart = (itemId, stock) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('DetailsCart')) || [];

        // Find the index of the item in the existing cart items array
        const itemIndex = existingCartItems.findIndex(item => item.id === itemId);

        // If the item is already in the cart, update its quantity
        if (itemIndex !== -1) {
            // Increment quantity if it doesn't exceed stock
            if (existingCartItems[itemIndex].count < stock) {
                existingCartItems[itemIndex].count += 1;
            } else {
                // Display an alert if the cart exceeds the stock
                alert("You cannot add more of this product. Stock limit reached.");
                return; // Stop further execution
            }
        } else {
            // If the item is not in the cart, add it with quantity 1
            if (1 <= stock) {
                existingCartItems.push({ id: itemId, count: 1, selectedColor: null, selectedSize: null });
            } else {
                // Display an alert if the stock is insufficient
                alert("Sorry, this product is out of stock.");
                return; // Stop further execution
            }
        }

        // Update the cart state and localStorage
        localStorage.setItem('DetailsCart', JSON.stringify(existingCartItems));

        // Display a success toast
        toast.success('Added to cart successfully!');
    };
    const handleAddToFavouriteList = (itemId) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('favouriteList')) || [];

        // Add the new item ID to the cart
        const updatedCartItems = [...existingCartItems, itemId];

        // Update the cart state and localStorage
        setCart(updatedCartItems);
        localStorage.setItem('favouriteList', JSON.stringify(updatedCartItems));

        // Display a success toast
        toast.success('Added to favourite List successfully!');
    };
    console.log(sortedProduct)
    console.log(sortByPrice)

    const handleShowMore = () => {
        setVisibleItems(prev => prev + 10); // Increase the visible items count by 10
    };

    const handleFilterByPriceRange = (minPrice, maxPrice) => {
        // Create a copy of the sortedProduct state
        const updatedSortedProduct = [...shopProduct];

        // Filter products based on the selected price range
        const filteredProducts = updatedSortedProduct.filter(product => {
            const price = parseFloat(product.offer_price);
            return price >= minPrice && price <= maxPrice;
        });

        // Update the sortedProduct state
        setSortedProduct(filteredProducts);

        // Uncheck all checkboxes
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        // Check the checkbox corresponding to the selected price range
        const checkboxId = `${minPrice}-${maxPrice}`;
        const selectedCheckbox = document.getElementById(checkboxId);
        if (selectedCheckbox) {
            selectedCheckbox.checked = true;
        }
    };


    return (
        <div className='mt-5 lg:flex  container mx-auto mb-10'>
            <div class="mt-5 box  rounded-xl border  border-gray-300 bg-white p-6 lg:h-[500px]  lg:w-[30%]">
                <select className="select mb-5 w-full bg-white select-bordered" value={sortByPrice} onChange={(e) => handleSortByPrice(e.target.value)}>
                    <option value="">Select Any</option>
                    <option value="desc">Higher To Lower</option>
                    <option value="asc">Lower To Higher</option>
                </select>
                <div class="box flex flex-col gap-5">
                    <div class="flex items-center">
                        <input id="100-300" name="100-300" onChange={() => handleFilterByPriceRange(100, 300)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">100 Tk to 300 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="300-600" name="300-600" onChange={() => handleFilterByPriceRange(300, 600)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">300 Tk to 600 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="600-1000" name="600-1000" onChange={() => handleFilterByPriceRange(600, 1000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">600 Tk to 1000 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="1000-1500" name="1000-1500" onChange={() => handleFilterByPriceRange(1000, 1500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">1000 Tk to 1500 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="1500-2000" name="1500-2000" onChange={() => handleFilterByPriceRange(1500, 2000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">1500 Tk to 2000 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="2000-2500" name="2000-2500" onChange={() => handleFilterByPriceRange(2000, 2500)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300">2000 Tk to 2500 Tk</label>
                    </div>
                    <div class="flex items-center">
                        <input id="2500-125000" name="2500-125000" onChange={() => handleFilterByPriceRange(2500, 125000)} type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                        <label className="ml-2 text-gray-700 font-semibold" htmlFor="100-300"> 2500 Tk ++</label>
                    </div>
                </div>
            </div>
            <div className='mx-auto max-w-[1440px] items-centergrid grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-5 gap-16 px-10'>

                {sortedProduct?.slice(0, visibleItems).map((product, index) => (
                    //   <Link to={`/product/product_details/${product.slug}`}>
                    <div
                        key={product.id}
                        className="bg-slate-100 glass w-full relative group text-start h-96"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <figure>
                            <img src={product.photo} style={{ height: '200px' }} alt="car!" className='w-full' />
                        </figure>

                        {hoveredIndex === index && (
                            <>

                                <div className="absolute top-40 left-0 ">


                                </div>
                                <div className="absolute top-0 right-0 ">
                                    <button onClick={() => handleAddToFavouriteList(product.id)} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"><FaHeart /></button>
                                </div>
                                {/* <div className="absolute top-0 left-0 ">
                                    <span class="absolute top-4 left-4 w-20 translate-y-2 -translate-x-6 -rotate-45 bg-black text-center text-sm text-white">{product.condition}</span>

                                </div> */}
                            </>
                        )}

                        <div className="card-body">

                            <Link to={`/product/product_details/${product.slug}`}>
                                <h2 className="card-title">{product.title.slice(0, 20) + '...'}</h2>
                            </Link>
                            <div className='flex gap-2'>
                                <div>{product.offer_price}</div>
                                <div> <del className='text-red-500'>{product.price}</del></div>
                            </div>
                            <p>Stock: {product.stock}</p>
                            <button onClick={() => handleAddToCart(product.id, product.stock)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                        Add to Cart</button>
                        </div>
                    </div>
                    // </Link>
                ))}
            </div>
            {sortedProduct.length > visibleItems && (
                <div className="flex justify-center mt-5">
                    <button onClick={handleShowMore} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                        Show More
                    </button>
                </div>
            )}
        </div>

    );
};

export default ProductSubCategory;

// <div className='container mx-auto items-center grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3  mt-5'>
//     {
//         shopProduct?.data?.map(product =>
//             <>
//     <div class="absolute h-full w-full bg-black/20 flex items-center justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
//     <button class="bg-black text-white py-2 px-5">Add to cart</button>
// </div>

//                 <div className=" lg:w-56 lg:h-72 md:w-56 md:h-72 w-full glass h-full ">
//                     <figure><img className='lg:h-48 lg:w-56 md:h-48 md:w-56 w-full h-full' src={product.photo} alt="car!" /></figure>
//                     <div className="card-body">
//                         {/* <h2 className="card-title">Life hack</h2>
//             <p>How to park your car at your garage?</p> */}

//                     </div>
//                 </div>
//             </>

//         )
//     }
// </div>