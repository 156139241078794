import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';


const HomeShop = () => {

   

   const [home, setHome] = useState([])
   const [loading, setLoading] = useState(false);

   useEffect(() => {
       setLoading(true);
       fetch(`https://backend.ebeachbd.com/api/shop-list`)
           .then(response => response.json())
           .then(data => {
               setHome(data);
               setLoading(false);
           });
   }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true, // Set autoplay to true
        autoplaySpeed: 3000,

        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <div className='px-7 mt-10 mb-10'>
{loading ? (
                    <button className="btn loading m-10 ">loading</button>
                ) : (
            <div className='container mx-auto '>
                <div className=" grid  grid-cols-1  ">
                    <div className='flex justify-between mb-3'>
                        <h1 className=' bg-[#be1e2d] font-semibold text-white text-xl px-2 rounded-md py-1 mb-5'>Our Shop</h1>
                        <Link to='/all_shop_home' className=' bg-[#be1e2d] font-semibold text-white text-xl px-2 rounded-md py-1 mb-5 underline'>Show More</Link>
                    </div>
                    <Slider className=''  {...settings}>
                        {home?.data?.map(item => (
                            <Link to={`/shop/all_product/${item.id}`}>
                                <div className="card rounded-none shadow-xl">
                                    <figure className="">
                                        <img src={item.photo} alt="Shoes" className="w-[200px] h-[200px] rounded-lg" />
                                    </figure>
                                    <p className='text-[#be1e2d]'>{item.name}</p>
                                </div>

                            </Link>
                        ))}

                    </Slider>
                </div>

            </div>
                )}
        </div>
    );
};

export default HomeShop;