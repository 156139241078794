import React from 'react';

const VoucherProduct = () => {
    return (
        <div>
            <img className='w-full h-[500px]' src="https://static.vecteezy.com/system/resources/thumbnails/003/582/701/small_2x/coming-soon-background-illustration-template-design-free-vector.jpg" alt="" />
        </div>
    );
};

export default VoucherProduct;